export default [
  {
    title: 'breadcrumb.transaction',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'breadcrumb.manualTopUp',
        route: 'list-manual-top-up',
        icon: 'DollarSignIcon',
        resource: 'manual-top-up',
        action: 'read',
      },
      {
        title: 'Manual Pre Order',
        route: 'list-manual-pre-order',
        icon: 'DollarSignIcon',
        resource: 'admin-pre-order',
        action: 'read',
      },
      {
        title: 'breadcrumb.customer',
        route: 'list-customer',
        icon: 'DollarSignIcon',
        resource: 'customer',
        action: 'read',
      },
      {
        title: 'breadcrumb.preOrder',
        route: 'list-pre-order',
        icon: 'DollarSignIcon',
        resource: 'pre-order',
        action: 'read',
      },
      {
        title: 'breadcrumb.transaction',
        route: 'list-transaction',
        icon: 'DollarSignIcon',
        resource: 'transaction',
        action: 'read',
      },
    ],
  },
]
