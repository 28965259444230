export default [
  {
    title: 'breadcrumb.package',
    icon: 'PackageIcon',
    children: [
      {
        title: 'breadcrumb.mobile-legend',
        route: 'list-mlbb-package',
        icon: 'PackageIcon',
        resource: 'mlbb-package',
        action: 'read',
      },
      {
        title: 'breadcrumb.pubgm',
        route: 'list-pubgm-package',
        icon: 'PackageIcon',
        resource: 'pubgm-package',
        action: 'read',
      },
      {
        title: 'breadcrumb.freefireSg',
        route: 'list-freefire-sg-package',
        icon: 'PackageIcon',
        resource: 'freefire-sg/my-package',
        action: 'read',
      },
      {
        title: 'breadcrumb.genshin-impact',
        route: 'list-genshin-impact-package',
        icon: 'PackageIcon',
        resource: 'genshin-impact-package',
        action: 'read',
      },
      {
        title: 'breadcrumb.farlight-84',
        route: 'list-farlight-84-package',
        icon: 'PackageIcon',
        resource: 'farlight-84',
        action: 'read',
      },
      {
        title: 'breadcrumb.clash-of-clans',
        route: 'list-clash-of-clans-package',
        icon: 'PackageIcon',
        resource: 'clash-of-clans',
        action: 'read',
      },
      {
        title: 'breadcrumb.wild-rift',
        route: 'list-wild-rift-package',
        icon: 'PackageIcon',
        resource: 'wild-rift',
        action: 'read',
      },
      {
        title: 'breadcrumb.zepeto',
        route: 'list-zepeto-package',
        icon: 'PackageIcon',
        resource: 'zepeto',
        action: 'read',
      },
      {
        title: 'breadcrumb.bloodstrike',
        route: 'list-blood-strike-package',
        icon: 'PackageIcon',
        resource: 'blood-strike',
        action: 'read',
      },
      {
        title: 'breadcrumb.sausage-man',
        route: 'list-sausage-man-package',
        icon: 'PackageIcon',
        resource: 'sausage-man',
        action: 'read',
      },
      {
        title: 'breadcrumb.identity-v',
        route: 'list-identity-v-package',
        icon: 'PackageIcon',
        resource: 'identity-v',
        action: 'read',
      },
      {
        title: 'Hay Day',
        route: 'list-hayday-package',
        icon: 'PackageIcon',
        resource: 'hayday',
        action: 'read',
      },
      {
        title: 'Honkai Star Rail',
        route: 'list-honkai-star-rail-package',
        icon: 'PackageIcon',
        resource: 'honkai-star-rail',
        action: 'read',
      },
      {
        title: 'Free Fire Thai',
        route: 'list-freefire-th-package',
        icon: 'PackageIcon',
        resource: 'freefire-th',
        action: 'read',
      },
      {
        title: 'Growtopia',
        route: 'list-growtopia-package',
        icon: 'PackageIcon',
        resource: 'growtopia',
        action: 'read',
      },
      {
        title: 'Bigo',
        route: 'list-bigo-package',
        icon: 'PackageIcon',
        resource: 'bigo',
        action: 'read',
      },
      {
        title: 'Honor of King',
        route: 'list-honor-of-king-package',
        icon: 'PackageIcon',
        resource: 'honor-of-king',
        action: 'read',
      },
    ],
  },
]
