import dashboard from './menu/dashboard'
import administrator from './menu/administrator'
import store from './menu/store'
import packages from './menu/package'
import transaction from './menu/transaction'
import banAccount from './menu/banAccount'
import configuration from './menu/configuration'
import report from './menu/report'
import wholeSale from './menu/wholeSale'
import checkPayment from './menu/checkPayment'
import abaCallback from './menu/abaCallback'

export default [
  ...dashboard,
  ...report,
  ...packages,
  ...banAccount,
  ...transaction,
  ...checkPayment,
  ...abaCallback,
  ...wholeSale,
  ...administrator,
  ...configuration,
]
