export default [{
  title: 'breadcrumb.administrator',
  icon: 'UserIcon',
  children: [
      {
        title: 'breadcrumb.administrator',
        route: 'list-administrator',
        icon: 'UserIcon',
        resource: 'administrator',
        action: 'read'
      },
      {
        title: 'breadcrumb.rolePermission',
        route: 'list-role-permission',
        icon: 'UnlockIcon',
        resource: 'role-permission',
        action: 'read'
      }
  ],
}];
