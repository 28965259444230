import store from './store'

export default [
  {
    title: 'breadcrumb.configuration',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'breadcrumb.game-setting',
        route: 'list-game-setting',
        icon: 'SettingsIcon',
        resource: 'game-type',
        action: 'read',
      },
      {
        title: 'breadcrumb.banner',
        route: 'list-banner',
        icon: 'SettingsIcon',
        resource: 'banner',
        action: 'read',
      },
      ...store,
      {
        title: 'breadcrumb.preOrder',
        route: 'list-preorderConfiguration',
        icon: 'WindIcon',
        resource: 'event',
        action: 'read',
      },
    ],
  },
]
