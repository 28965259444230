export default [
  {
    title: 'breadcrumb.checkTransaction',
    icon: 'SearchIcon',
    children: [
      {
        title: 'breadcrumb.abaTransaction',
        route: 'list-check-transaction',
        icon: 'SearchIcon',
        resource: 'check-transaction',
        action: 'read',
      },
      {
        title: 'breadcrumb.partnerTransaction',
        route: 'list-check-partner-transaction',
        icon: 'SearchIcon',
        resource: 'check-transaction',
        action: 'read',
      },
      {
        title: 'breadcrumb.listTransactionPartner',
        route: 'list-partner-transaction',
        icon: 'SearchIcon',
        resource: 'list-partner-transaction',
        action: 'read',
      },
      {
        title: 'breadcrumb.retopupStatus',
        route: 'list-check-retopup-transaction',
        icon: 'SearchIcon',
        resource: 'check-transaction',
        action: 'read',
      },
      {
        title: 'ReTopUp Partner',
        route: 'list-retopup-partner-transaction',
        icon: 'SearchIcon',
        resource: 'check-transaction',
        action: 'read',
      },
    ],
  },
]
